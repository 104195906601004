import React from 'react';
import './Surveyor-confirmed.css';
import Card from 'react-bootstrap/Card';

export default class SurveyorConfirmed extends React.Component {
    render() {
      return (
        <Card className="surveyor-confirmed mx-auto">
          <Card.Header>CONFIRMED</Card.Header>
          <Card.Body>
            <Card.Text className="cardHighlight">{this.props.location.state.vesselType}, {this.props.location.state.inspectionType} at {this.props.location.state.location} on {new Intl.DateTimeFormat('en-GB', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
              }).format(new Date(this.props.location.state.dateFrom))}.</Card.Text>
            <Card.Text>You have confirmed your availability for this inspection and agreed to a fee of {this.props.location.state.currencySymbol + this.props.location.state.inspectionFee}.</Card.Text>
            <Card.Text>If you need to make a change or require any further information, please contact operations@idwalmarine.com</Card.Text>
            <Card.Text>You can now close this window.</Card.Text>
          </Card.Body>
        </Card> 
      );
    }
  }