export const INSPECTION_DECLINED_MESSAGE = `You have indicated that you are unavailable for this inspection. 
\nIf you need to make a change or require further assistance, please contact operations@idwalmarine.com 
\nYou can now close this window.`;

export const INSPECTION_CLOSED_MESSAGE = `Sorry this inspection is no longer available. 
\nThank you for your interest. 
\nYou can now close this window.`;

export const ERROR_MESSAGE = `An error has occured.
\nPlease contact operations@idwalmarine.com to inform us of this error.
\nThank you for your patience.`

export const DECLINED_HEADER = "DECLINED";
export const CLOSED_HEADER = "CLOSED";
export const ERROR_HEADER = "ERROR";