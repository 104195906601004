import Axios from 'axios';

const SURVEY_APP_API_URL = `https://${process.env.REACT_APP_SURVEY_APP_API_ID}.execute-api.${process.env.REACT_APP_SURVEY_APP_API_REGION}.amazonaws.com/${process.env.REACT_APP_DEPLOY_ENV}`;
const SURVEY_APP_API_TOKEN = process.env.REACT_APP_SURVEY_APP_API_TOKEN;

const API_HEADERS = {
    "Content-Type": "application/json",
    "x-api-key": SURVEY_APP_API_TOKEN
};

export async function getSurveyorPrice(inspectionId, quotationId, surveyorId) {
    const surveyorPriceEndpoint = `/surveyors/${surveyorId}/price`;

    try {
        const response = await Axios({
            method: "GET",
            url: SURVEY_APP_API_URL + surveyorPriceEndpoint,
            headers: API_HEADERS,
            params: {
                inspectionId: inspectionId,
                quotationId: quotationId
            }
        });

        console.debug(response);
        return response?.data;
    } catch (error) {
        console.error(error);
        const inspectionOrQuotation = inspectionId ? `inspection: ${inspectionId}` : `quotation: ${quotationId}`;
        console.debug(`Failed to get surveyor price for ${inspectionOrQuotation} for surveyor: ${surveyorId}`);
    }
}

export async function getDetails(inspectionId, quotationId, surveyorId) {
    const detailsEndpoint = !inspectionId ? `/quotations/${quotationId}/details` : `/inspections/${inspectionId}/details`;

    try {
        const response = await Axios({
            method: "GET",
            headers: API_HEADERS,
            params: {
              surveyorId: surveyorId
            },
            url: SURVEY_APP_API_URL + detailsEndpoint
        });

        console.debug(response);
        return response?.data;
    } catch (error) {
        console.error(error);
        const inspectionOrQuotation = inspectionId ? `inspection: ${inspectionId}` : `quotation: ${quotationId}`;
        console.debug(`Failed to get details for ${inspectionOrQuotation} and surveyor: ${surveyorId}`);
    }
}

export async function getPriceDetails(locationId, surveyorId, inspectionId, quotationId) {
    const priceDetailsEndpoint = !inspectionId ? `/quotations/${quotationId}/price-details` : `/inspections/${inspectionId}/price-details`;

    try {
        const response = await Axios({
            method: "GET",
            headers: API_HEADERS,
            params: {
                surveyorId: surveyorId,
                locationId: locationId
            },
            url: SURVEY_APP_API_URL + priceDetailsEndpoint
        });

        console.debug(response);
        return response?.data;
    } catch (error) {
        console.error(error);
        const inspectionOrQuotation = inspectionId ? `inspection: ${inspectionId}` : `quotation: ${quotationId}`;
        console.debug(`Failed to get price details for ${inspectionOrQuotation} and surveyor: ${surveyorId}`);
    }
}

export async function getCurrencySymbol(surveyorId) {
    const surveyorCurrencySymbolEndpoint = `/surveyors/${surveyorId}/currency-symbol`;

    try {
        const response = await Axios({
            method: "GET",
            url: SURVEY_APP_API_URL + surveyorCurrencySymbolEndpoint,
            headers: API_HEADERS
        });

        console.debug(response);
        return response?.data;
    } catch (error) {
        console.error(error);
        console.debug(`Failed to get currency symbol for surveyor: ${surveyorId}`);
    }
}

export async function confirmInspection(confirmationData) {
    const confirmEndpoint = !confirmationData.inspectionId ? `/quotations/${confirmationData.quotationId}/confirm` : `/inspections/${confirmationData.inspectionId}/confirm`;

    const response = await Axios({
        method: "POST",
        url: SURVEY_APP_API_URL + confirmEndpoint,
        headers: API_HEADERS,
        params: {
            surveyorId: confirmationData.surveyorId,
            confirmedOffer: confirmationData.confirmedOffer,
            currencySymbol: confirmationData.currencySymbol,
            dateFrom: confirmationData.dateFrom,
            dateTo: confirmationData.dateTo
        }
    });

    return response?.data;
}

export async function declineInspection(declineData) {
    const declineEndpoint = !declineData.inspectionId ? `/quotations/${declineData.quotationId}/decline` : `/inspections/${declineData.inspectionId}/decline`;

    const response = await Axios({
        method: "POST",
        url: SURVEY_APP_API_URL + declineEndpoint,
        headers: API_HEADERS,
        params: {
            surveyorId: declineData.surveyorId
        }
    });

    return response?.data;
}
