import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes } from "./routes";

export const App = () => {
  return (
    <div className="App">
    <header className="App-header">
      <img src="IDWAL-Logo-Reversed.svg" alt="Idwal Marine" id="idwal-logo"/>
    </header>
    <Routes />
  </div>
  );
}