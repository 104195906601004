import React from 'react';
import './Inspection-closed.css';
import Card from 'react-bootstrap/Card';

export default class InspectionClosed extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      inspectionDate: undefined,
      };
    if (this.props.location.state) {
      this.state.inspectionDate = this.props.location.state.dateFrom;
    }
  }
    render() {
      return (
        <Card className="inspection-closed mx-auto">
          <Card.Header>{this.props.location.state.header}</Card.Header>
          <Card.Body>
            {this.state.inspectionDate &&
              <Card.Text className="cardHighlight">{this.props.location.state.vesselType}, {this.props.location.state.inspectionType} at {this.props.location.state.location} on {new Intl.DateTimeFormat('en-GB', { 
                  year: 'numeric', 
                  month: 'long', 
                  day: '2-digit' 
                }).format(new Date(this.props.location.state.dateFrom))}</Card.Text>
            }
            <Card.Text>{this.props.location.state.content}</Card.Text>
          </Card.Body>
        </Card> 
      );
    }
  }