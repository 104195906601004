import React from 'react';
import { Route, Switch } from "react-router-dom"
import './App.css';

import InspectionDetails from "./inspectionDetails/Inspection-details";
import SurveyorConfirmed from "./surveyorConfirmed/Surveyor-confirmed";
import InspectionClosed from "./inspectionClosed/Inspection-closed";

export const Routes = () => {
  return (
    <Switch>
        <Route exact path="/" component={InspectionDetails} />
        <Route path="/surveyor-confirmed/" component={SurveyorConfirmed} />
        <Route path="/inspection-closed/" exact component={InspectionClosed} />
        <Route render={ () => <h1>404 Error</h1> } />
    </Switch>
  );
};