import React from 'react';
import './Inspection-details.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QueryString from 'query-string';
import Moment from 'moment';
import Collapse from 'react-bootstrap/Collapse';
import { confirmInspection, declineInspection, getCurrencySymbol, getDetails, getPriceDetails, getSurveyorPrice } from './api';
import { CLOSED_HEADER, DECLINED_HEADER, ERROR_HEADER, ERROR_MESSAGE, INSPECTION_CLOSED_MESSAGE, INSPECTION_DECLINED_MESSAGE } from './constants';

export default class InspectionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmit: false,
      showDecline: false,
      showAcceptTermsAndConditionsMessage: false,
      acceptTermsAndConditions: false,
      termsAndConditionsLink: "https://f.hubspotusercontent20.net/hubfs/6251429/IdwalSurveyorTermsAndConditions.pdf?__hstc=&__hssc=&hsCtaTracking=a182f908-3ccb-4f82-bba8-3f1edcd35fc0%7C8eb010d6-a0f8-4e8e-8695-e84a5494ded5",
      inspectionDateTo: null,
      inspectionDateFrom: null,
      location: null,
      locationId: null,
      inspectionType: null,
      vesselType: null,
      inspectionFee: null,
      locationRecommendedCost: null,
      inspectionFeeExtendedWarning: false,
      showFeeWarningModal: false,
      value: '',
      inspectionId: QueryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).inspectionId,
      quotationId: QueryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).quotationId,
      surveyorId: QueryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).surveyorId,
      currencySymbol: "$",
      dataLoaded: false,
      isSubmitting: false,
      isDeclining: false
    };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalYes = this.handleModalYes.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.handleDeclineClose = this.handleDeclineClose.bind(this);
    this.handleFeeWarningModalClose = this.handleFeeWarningModalClose.bind(this);
    this.handleSubmitClose = this.handleSubmitClose.bind(this);
    this.handleFeeInput = this.handleFeeInput.bind(this);
    this.toggleAcceptTermsAndConditions = this.toggleAcceptTermsAndConditions.bind(this);
  }

  async componentDidMount() {
    const { details, surveyorPrice, currencySymbol, priceDetails } = await this.loadData();

    const inspectionStartDate = Moment(details.InspectionDateFrom, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const dateNow = Moment();
    const surveyorAlreadySubmitted = Object.keys(surveyorPrice).length !== 0;

    if (process.env.NODE_ENV !== 'production') {
      if (details.InspectionDeclined) {
        console.log("Rejected as the inspection has been declined");
      }

      if (surveyorAlreadySubmitted) {
        console.log("Rejected as a surveyor has already been submitted for this inspection");
      }

      if (!inspectionStartDate.isValid() || !dateNow.isBefore(inspectionStartDate)) {
        console.log("Rejected as the inspection start date is invalid or in the past");
      }
    }

    if(details.InspectionDeclined === false && surveyorAlreadySubmitted === false && inspectionStartDate.isValid() && dateNow.isBefore(inspectionStartDate)) {
      this.setInspectionState(details, currencySymbol, priceDetails);
    } else {
      let content;
    let header;
      if (details.InspectionDeclined) {
        content = INSPECTION_DECLINED_MESSAGE;
        header = DECLINED_HEADER;
      } else {
        content = INSPECTION_CLOSED_MESSAGE;
        header = CLOSED_HEADER;
      }
      this.props.history.push({
        pathname: '/inspection-closed',
        state: {
          location: details.InspectionLocation,
          inspectionType: details.TypeOfInspection,
          vesselType: details.TypeOfVessel,
          dateFrom: details.InspectionDateFrom,
          dateTo: details.InspectionDateTo,
          content,
          header
          }
      });
    }
  }

  confirm() {
    const confirmationData = {
      surveyorId: this.state.surveyorId,
      confirmedOffer: this.state.inspectionFee || this.state.value,
      currencySymbol: this.state.currencySymbol,
      dateFrom: this.state.inspectionDateFrom,
      dateTo: this.state.inspectionDateTo,
      inspectionId: this.state.inspectionId,
      quotationId: this.state.quotationId,
    };

    this.setState({
      isSubmitting: true
    });

    return new Promise((resolve) => {
      confirmInspection(confirmationData)
      .then(data => {
        console.debug(data);
        resolve(data);
      }).catch(error => {
        console.error(error);
        const inspectionOrQuotation = confirmationData.inspectionId ? `inspection: ${confirmationData.inspectionId}` : `quotation: ${confirmationData.quotationId}`;

        console.debug(`Failed to confirm ${inspectionOrQuotation} for surveyor: ${confirmationData.surveyorId}`);
      });
    });
  }

  async decline() {
    const declineData = {
      surveyorId: this.state.surveyorId,
      inspectionId: this.state.inspectionId,
      quotationId: this.state.quotationId
    };

    this.setState({
      isDeclining: true
    });

    return new Promise((resolve) => {
      declineInspection(declineData)
        .then(data => {
          console.debug(data);
          resolve(data);
        }).catch(error => {
          console.error(error);
          const inspectionOrQuotation = declineData.inspectionId ? `inspection: ${declineData.inspectionId}` : `quotation: ${declineData.quotationId}`;
          console.debug(`Failed to decline ${inspectionOrQuotation} for surveyor: ${declineData.surveyorId}`);
        });
    });
  }

  toggleAcceptTermsAndConditions(event) {
    const checked = event.target.checked;
    this.setState(({ acceptTermsAndConditions: checked }));
  }

  showModal = (modal) => {
    this.setState({ [modal]: true });
  };

  handleModalClose = (modal) => {
    this.setState({ [modal]: false });
  };

  handleModalYes = () => {
    let acceptedTerms;

    if (this.state.acceptTermsAndConditions) {
      this.setState(({ showAcceptTermsAndConditionsMessage: false }))
      acceptedTerms = true;
    }
    else {
      this.setState(({ showAcceptTermsAndConditionsMessage: true }))
      acceptedTerms = false;
    }

    if (acceptedTerms) {
      this.confirm()
      .then(data => {
        this.props.history.push({
          pathname: '/surveyor-confirmed',
          state: {
            inspectionFee: this.state.value,
            currencySymbol: this.state.currencySymbol,
            location: this.state.location,
            inspectionType: this.state.inspectionType,
            vesselType: this.state.vesselType,
            dateFrom: this.state.inspectionDateFrom,
            dateTo: this.state.inspectionDateTo
            }
        });
      });
    }

  }

  handleDeclineYes = () => {
    this.decline()
      .then(data => {
        let content;
        let header;
        if(data.Success) {
          content = INSPECTION_DECLINED_MESSAGE;
          header = DECLINED_HEADER;
        } else {
          content = ERROR_MESSAGE;
          header = ERROR_HEADER;
        }

        this.props.history.push({
          pathname: '/inspection-closed',
          state: {
            location: this.state.location,
            inspectionType: this.state.inspectionType,
            vesselType: this.state.vesselType,
            dateFrom: this.state.inspectionDateFrom,
            dateTo: this.state.inspectionDateTo,
            content,
            header
            }
        });
      });
  }

  handleSubmit(event) {
    if (this.state.value > this.state.maximumCost && this.state.maximumCost !== 0 && this.state.maximumCost !== null && this.state.locationRecommendedCost !== null) {
      this.setState(({ showFeeWarningModal: true, inspectionFeeExtendedWarning: true }))
      event.preventDefault();
    }
    else {
      this.showModal("showSubmit");
      event.preventDefault();
    }
  }

  handleSubmitClose() {
    this.handleModalClose("showSubmit");
  }

  handleDecline(event) {
    this.showModal("showDecline");
    event.preventDefault();
  }

  handleDeclineClose() {
    this.handleModalClose("showDecline");
  }

  handleFeeWarningModalClose() {
    this.handleModalClose("showFeeWarningModal");
  }

  handleFeeInput(event) {
    this.setState({value: event.target.value});
  }

  async loadData() {
    try {
      const [details, surveyorPrice, currencySymbol] = await Promise.all([
        getDetails(this.state.inspectionId, this.state.quotationId, this.state.surveyorId),
        getSurveyorPrice(this.state.inspectionId, this.state.quotationId, this.state.surveyorId),
        getCurrencySymbol(this.state.surveyorId),
      ]);

      const priceDetails = await getPriceDetails(
        details.InspectionLocationId,
        this.state.surveyorId,
        this.state.inspectionId,
        this.state.quotationId
      );
  
      return {
        details,
        surveyorPrice,
        currencySymbol,
        priceDetails
      };
    } catch (error) {
      console.error(error);
      console.debug(`Failed to load data for inspection: ${this.state.inspectionId} and surveyor: ${this.state.surveyorId}`);
    }
  }

  setInspectionState(details, currencySymbol, priceDetails) {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Inspection passed validation checks - rendering bid submission interface...");
    }

    if (details && currencySymbol) {
      this.setState({
        inspectionDateTo: details.InspectionDateTo,
        inspectionDateFrom: details.InspectionDateFrom,
        location: details.InspectionLocation,
        inspectionType: details.TypeOfInspection,
        vesselType: details.TypeOfVessel,
        locationId: details.InspectionLocationId,
        currencySymbol: currencySymbol,
        maximumCost: details.LocationMaximumCost
      });
    }

    if (priceDetails) {
      if(priceDetails.InspectionFixedCost !== null) {
        this.setState({
          inspectionFee: priceDetails.InspectionFixedCost,
          value: priceDetails.InspectionFixedCost
        });
      }
      else if(priceDetails.LocationRecommendedCost !== null) {
        this.setState({
          locationRecommendedCost: priceDetails.LocationRecommendedCost,
        });
      }
    }

    this.setState({
      dataLoaded: true
    });
  }

  render() {
    let costElement = <p>{this.state.currencySymbol + this.state.inspectionFee}</p>;
    if(this.state.inspectionFee === null){
      costElement = <div className="form-row">
        <div className="input-group">
          <span className="input-group-addon">{this.state.currencySymbol}</span>
          <input type="number" className="form-control form-control-sm currency" id="inspectionFee" aria-describedby="inspectionFeeHelp" placeholder="Enter fee here" value={this.state.value} onChange={event => this.handleFeeInput(event)}/>
        </div>
      </div>
    }

    return (
      <div className="Inspection">
        <h1>INSPECTION DETAILS</h1>
        {this.state.dataLoaded ? (
          <div className="Inspection-details">
            <div className="Inspection-labels">
              <p>Inspection Date</p>
              <p>Location</p>
              <p>Inspection Type</p>
              <p>Vessel Type</p>
              {this.state.locationRecommendedCost != null ? <div/> : <p>Inspection Fee</p>}
            </div>
            <div className="Inspection-values">
              <p>
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(this.state.inspectionDateFrom))}

                {this.state.inspectionDateTo &&
                this.state.inspectionDateTo !== this.state.inspectionDateFrom
                  ? `- ${new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    }).format(new Date(this.state.inspectionDateTo))}`
                  : null}
              </p>
              <p>{this.state.location}</p>
              <p>{this.state.inspectionType}</p>
              <p>{this.state.vesselType}</p>
              {this.state.locationRecommendedCost != null ? <div/> : costElement}
            </div>
          </div>
        ) : (
          <div className="loading-details">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <span>Loading inspection details...</span>
          </div>
        )}
        {this.state.dataLoaded && this.state.locationRecommendedCost !== null ? (
          <div>
            {this.state.inspectionFeeExtendedWarning ? (
              <div className="Inspection-recommended-cost-extended">
                <p className="Inspection-recommended-cost-text">
                  {`This location has a fee limit. The maximum allowable bid is ${this.state.currencySymbol}${this.state.maximumCost}. Please enter a fee that does not exceed this amount.`}
                </p>
              </div>
            ) : (
              <div className="Inspection-recommended-cost">
                <p className="Inspection-recommended-cost-text">
                  The recommended fee for this port is{" "}
                  {this.state.currencySymbol +
                    this.state.locationRecommendedCost}
                  . Please submit your fee.
                </p>
              </div>
            )}

            <div className="Inspection-details">
              <div className="Inspection-labels">
                <p>Inspection Fee</p>
              </div>

              <div className="Inspection-values">{costElement}</div>
            </div>
          </div>
        ) : (
          <div />
        )}

        {this.state.dataLoaded && (
          <div className="buttons">
            <Button variant="primary" onClick={this.handleSubmit}>
              CONFIRM AVAILABILITY
            </Button>
            <p id="button-or">Or</p>
            <Button id="decline-button" onClick={this.handleDecline}>
              DECLINE
            </Button>
          </div>
        )}

        <Modal
          centered
          show={this.state.showSubmit}
          onHide={this.handleSubmitClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>CONFIRM INSPECTION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="Inspection-modal-body-text">
              Before confirming your availability you must agree to the standard{" "}
              <a
                className="Inspection-modal-body-link"
                href={this.state.termsAndConditionsLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms and Conditions
              </a>{" "}
              for the Services:
            </p>
            <input
              className={
                this.state.showAcceptTermsAndConditionsMessage &&
                !this.state.acceptTermsAndConditions
                  ? "Inspection-checkbox Inspection-checkbox-error"
                  : "Inspection-checkbox"
              }
              onChange={this.toggleAcceptTermsAndConditions}
              type="checkbox"
            />{" "}
            I accept the terms
            <Collapse in={this.state.showAcceptTermsAndConditionsMessage}>
              <p className="Inspection-message ">
                You must accept the terms to continue
              </p>
            </Collapse>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="form-submit-button"
              variant="secondary"
              onClick={this.handleModalYes}
              disabled={this.state.isSubmitting}
            >
              {this.state.isSubmitting && (
                <div className="mr-2">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              YES
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSubmitClose}
              disabled={this.state.isSubmitting}
            >
              NO
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={this.state.showDecline}
          onHide={this.handleDeclineClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>DECLINE INSPECTION</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button
              className="form-submit-button"
              variant="secondary"
              onClick={this.handleDeclineYes}
              disabled={this.state.isDeclining}
            >
              {this.state.isDeclining && (
                <div className="mr-2">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              YES
            </Button>
            <Button
              variant="primary"
              onClick={this.handleDeclineClose}
              disabled={this.state.isDeclining}
            >
              NO
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={this.state.showFeeWarningModal}
          onHide={this.handleFeeWarningModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>CONFIRM INSPECTION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This location has a fee limit. The maximum allowable bid is{" "}
            {this.state.currencySymbol}
            {this.state.maximumCost}. If you wish to continue, please enter a
            fee that does not exceed this amount and try again.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.handleFeeWarningModalClose}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
